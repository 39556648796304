import { createContext, useContext, useReducer, useEffect} from 'react';
import { SERVER } from './App';

const AuthContext = createContext();

export const authReducer = (state, action) => {
    switch(action.type) {
        case 'LOGIN':
            return { user: action.payload }
        case 'LOGOUT':
            localStorage.removeItem('user')
            return { user: null }
        default:
            return state
    }
}

export const AuthContextProvier = ({children}) => {
    const [state, dispatch] = useReducer(authReducer, {user: null})

    useEffect(() => {
        (async () => {
            const user = await JSON.parse(localStorage.getItem('user'))
            if (user) {
                const res = await fetch(`${SERVER}/session/${user._id}`, {
                    method: 'GET',
                    headers: {
                        "Authorization": `Bearer ${user.token}`
                    }
                })
                const json = await res.json()
                dispatch({ type: 'LOGIN', payload: {...json._doc, token: user.token} }) 
            }
        })()
    }, [])

    return (
        <AuthContext.Provider value={{...state, dispatch}}>
            {children}
        </AuthContext.Provider>
    )

}

export const useAuth = () => useContext(AuthContext);