import { useAuth } from "../../AuthContext"
import Dropdown from "../common/Dropdown"

export default function UserNav(){
    const { user, dispatch } = useAuth()
    return (
        <Dropdown name={user.name}>
            {user.is_admin && (
                <li>
                    <a className="dropdown-item" href="/product/create">Create Product</a>
                </li>
            )}
            {user.is_admin && (
                <li>
                    <a className="dropdown-item" href="/product/archived">Archived Product</a>
                </li>
            )}
            <li>
                <a className="dropdown-item" href="#!" onClick={() => dispatch({type: "LOGOUT"})}>Logout</a>
            </li>
        </Dropdown>
    )
}