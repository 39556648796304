import { BrowserRouter, Routes , Route } from "react-router-dom";
import Login from "./components/Login/Login";
import Home from './components/Home/Home';
import ContextProvider from "./Context";
import Cart from "./components/Cart/Cart";
import ProductForm from "./components/CreateProduct/ProductForm";
import { AuthContextProvier } from "./AuthContext";
import UpdateForm from "./components/CreateProduct/UpdateForm";
import Archived from "./components/Home/Archived";
import NotFound from "./components/common/NotFound";
import {NotificationContainer} from 'react-notifications';
import DatabaseManager from "./components/Troll/DatabaseManger";
import PasswordReset from "./components/Troll/PasswordReset";

export const SERVER = 'https://cafe.cybrosys.net/api'

export default function App() {
  return (
    <AuthContextProvier>
      <ContextProvider>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Home/>}/>
            <Route path="/web/login" element={<Login/>}/>
            <Route path="/shop/cart" element={<Cart/>}/>
            <Route path="/product/create" element={<ProductForm/>}/>
            <Route path="/product/update/:id" element={<UpdateForm/>}/>
            <Route path="/product/archived" element={<Archived/>}/>
            <Route path="/web/database/manager" element={<DatabaseManager/>}/>
            <Route path="/web/database/selector" element={<DatabaseManager selector={true}/>}/>
            <Route path="/web/reset_password" element={<PasswordReset/>}/>
            <Route path="*" element={<NotFound/>}/>
          </Routes>
        </BrowserRouter>
        <NotificationContainer/>
      </ContextProvider>
    </AuthContextProvier>
  );
}
