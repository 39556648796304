import CategoryMobile from './CategoryMobile';
import Category from "./Category";
import ProductContainer from "./ProductContainer";
import { usePantry } from '../../Context';
import { useEffect } from "react";
import Loading from "./Loading";
import Row from '../common/Row';
import { useSearchProduct } from '../hooks/useSearchProduct';

function Section() {
    const {products, currentCategory, productExist, page} = usePantry()
    const { search } = useSearchProduct()
    useEffect(() => {
        search(`product/${currentCategory}?page=${page}`)
    }, [currentCategory, page])
    if (!products.length && productExist){
        return (<Loading/>)
    }
  return (
    <section className="pantry_product">
      <div className="f_wrapper">
        <div className="container-fluid">
          <Row>
              <CategoryMobile/>
              <Category/>
              <div className="col">
                <ProductContainer products={products}/>
              </div>
          </Row>
        </div>
      </div>
    </section>
  );
}

export default Section;
