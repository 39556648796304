import { useState } from 'react'
import { SERVER } from '../../App'
import { useAuth } from '../../AuthContext'
import { NotificationManager } from 'react-notifications'
import { useSearchProduct } from './useSearchProduct'

export const useCreateProduct = () => {
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  const { user } = useAuth()
  const { search } = useSearchProduct()
  
  const create = async (val) => {
    setIsLoading(true)
    setError(null)

    const response = await fetch(`${SERVER}/product/create`, {
        method: 'POST',
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token}`
        },
        body: JSON.stringify(val)
    })
    const json = await response.json()
    if (!response.ok) {
      setIsLoading(false)
      setError(json.error)
      NotificationManager.error(json.error, 'Failed', 1000);
    }
    if (response.ok) {
      setIsLoading(false)
      NotificationManager.success('Product Created', 'Created', 1000);
    }
  }

  const update = async (val, id) => {
    setIsLoading(true)
    setError(null)

    fetch(`${SERVER}/product/update/${id}`, {
        method: 'PATCH',
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token}`
        },
        body: JSON.stringify(val)
    }).then(res => res.json())
    .then(json => {
      if(json.error) {
        setIsLoading(false)
        setError(json.error)
        NotificationManager.error(json.error, 'Failed', 1000);
      }
      if (json.message) {
        setIsLoading(false)
        search(`product/All`)
        NotificationManager.success(json.message, 'Updated', 1000);
      }
    })
  }

  return { create, update, isLoading, error }
}