import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { usePantry } from "../../Context";

const createPages = (count) => [...Array(count).keys()]
function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
export default function Pagination(){
    const {page, setPage, totalPage} = usePantry()
    const pageArray = createPages(totalPage)
    const onClickPagination = (page) => {
        setPage(page)
        topFunction()
    } 
    if (totalPage === 1) return
    return(
        <div className="pagination">
            <ul>
                {page > 1 && <li className="btn prev" onClick={() => onClickPagination(page-1)}><span><FaArrowLeft/></span></li>}
                {page > 2 && <li className='first numb' onClick={() => onClickPagination(1)}><span>{1}</span></li>}
                {page > 3 && <li className="dots"><span>...</span></li>}
                {page && pageArray.map((a, i) => (
                    (a+1) <= (page + 1) && (a + 1) >= (page - 1)? 
                    <li key={i} className={page === a + 1 ? 'numb active': 'numb'} onClick={() => onClickPagination(a+1)}><span>{a + 1}</span></li> 
                    : ''
                ))}
                {(totalPage - 2) > page  && <li className="dots"><span>...</span></li>}
                {(totalPage - 1) > page &&<li className='last numb' onClick={() => onClickPagination(totalPage)}><span>{totalPage}</span></li>}
                {page < totalPage && <li className="btn next" onClick={() => onClickPagination(page+1)}><span><FaArrowRight/></span></li>}
            </ul>
        </div>
    )
}