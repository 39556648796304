import FormContainer from "../common/FormContainer"
import Row from "../common/Row"
import { Input, InputRef } from "../common/Input"
import Submit from "../common/Submit"
import { useRef, useEffect } from 'react'
import { useCreateProduct } from "../hooks/useCreateProduct"
import ErrorBox from '../common/ErrorBox'
import { useAuth } from "../../AuthContext"
import NotFound from "../common/NotFound"
import { usePantry } from "../../Context"
import { SERVER } from "../../App"
import Autocomplete from "../common/AutoComplete"
import FloatField from "../common/FloatField"

export default function ProductForm(){
    const { user } = useAuth()
    const { category, setCategory} = usePantry();
    const options = category.map((catg) => catg.name)
    useEffect(() => {
        fetch(`${SERVER}/category`)
          .then(res=>res.json())
          .then(json=> setCategory(json))
      }, [setCategory])
    let image = undefined;
    const name = useRef();
    const mrpPrice = useRef();
    const cybroPrice = useRef();
    const itemCode = useRef();
    const categoryRef = useRef();
    const barcodeRef = useRef();
    const { create, isLoading, error } = useCreateProduct()
    const formSubmit = (ev) => {
        ev.preventDefault();
        const val = {
            name: name.current.value,
            mrpPrice: mrpPrice.current.value,
            cybroPrice: cybroPrice.current.value,
            itemCode: itemCode.current.value,
            category: categoryRef.current.value,
            barcode: barcodeRef.current.value,
            image: image
        }
         create(val)
        if(!error){
            name.current.value = '';
            mrpPrice.current.value = '';
            cybroPrice.current.value = '';
            itemCode.current.value = '';
            categoryRef.current.value = '';
            barcodeRef.current.value = '';
        }
    }
    const onChange = (e) => {
        let file = e.target.files[0];
        if(file){
            const reader = new FileReader();
            reader.onload = (ev) => {
                image = ev.target.result;
            }
            reader.readAsDataURL(file)
        }
    }
    if(!user || !user.is_admin) return (<NotFound/>)
    return (
        <FormContainer>
            <div className="heading">
                <h1 className="text text-large">Create Product</h1>
            </div>
            <form className="form" encType="multipart/form-data" id="product-create-form" onSubmit={formSubmit}>
                <div className="section">
                    <InputRef type="text" name="name" label="Name" id="name" hidden={true} refName={name}/>
                    <FloatField name="mrpPrice" label="Mrp Price" id="mrpPrice" hidden={true} refName={mrpPrice}/>
                    <FloatField name="cybroPrice" label="Cybro Price" id="cybroPrice" hidden={true} refName={cybroPrice} />
                    <InputRef required={false} type="text" name="itemCode" label="Code" id="itemCode" hidden={true} refName={itemCode} />
                    <Autocomplete
                        suggestions={options} type="text" name="category" id="category" label="Category" hidden={true} refName={categoryRef} />
                    <InputRef required={false} type="text" name="barcode" label="Barcode" id="barcode" hidden={true} refName={barcodeRef}/>
                    <Input type="file" name="image" label="Image" id="image" hidden={true} change={onChange}/>
                </div>
                {error && <ErrorBox error={error}/>}
                <div className="section">
                    <Row>
                        <Submit label="Save" disable={isLoading}/>
                    </Row>
                </div>
            </form>
        </FormContainer>
    )
}