import './cart.css'
import Row from '../common/Row'
import CartItem from './CartItem'
import EmptyCart from './EmptyCart';
import { usePantry } from '../../Context';
import PayNow, { PayNowIOS } from './PayNow';

function iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

export default function CartBody({currentProducts, count}){
    const { cartProduct, setCartProduct } = usePantry()
    let total = 0
    cartProduct.forEach((pdct) => {
        const product = currentProducts.filter((pd) => pd._id === pdct._id)
        total += product[0].cybroPrice * pdct.quantity
    })
    total = Math.round(total * 100) / 100
    return (
        <div className="col-md-12 cart">
            <div className="title">
                <Row>
                    <div className="col d-flex justify-content-center"><h4><b>Cafe Cart</b></h4></div>
                </Row>
            </div>
            {count > 0 &&
            <Row>
                <div className='col-12 justify-content-end d-flex'>
                    <a href='#!' className='btn bt-primary w-auto' onClick={() => {
                        setCartProduct([])
                        localStorage.removeItem('products')
                        }}>
                        Clear cart
                    </a>
                </div>
            </Row>}
            <Row className="border-top">
                {count > 0 ? currentProducts.map((product, i) => (
                    <CartItem key={i} {...product}/>
                )) : <EmptyCart/>}
                <Row className="main align-items-center border-top">
                    <div className='col-lg-8 col-6' style={{textAlign: 'end'}}>Total: </div>
                    <div className='col-lg-2 col-6'>₹{total}</div>
                </Row>
            </Row>
            {! iOS() && <PayNow total={total}/>}
            {iOS() && <PayNowIOS total={total}/>}
            <a className="back-to-shop" href="/"><span>&larr;</span><span className="text-muted">Back to Cafe</span></a>
        </div>
    )
}