import { useEffect } from 'react';
import { SERVER } from '../../App';
import { usePantry } from '../../Context'

export default function Category(){
    const { categoryClass, currentCategory, setCurrentCategory, category, setCategory, setPage } = usePantry();
    let all = ['All']
    useEffect(() => {
        fetch(`${SERVER}/category`)
          .then(res=>res.json())
          .then(json=> {
            json.forEach((categ) => all.push(categ.name))
            setCategory(all)
          })
      }, [setCategory])
    return (
        <div className={categoryClass}>
            <div className="card w-100">
                <div className="card-header">
                    Categories
                </div>
                <div className="card-body">
                    <ul className="nav flex-column">
                        {category.map((catg, i) => (
                            <li className="nav-item" style={{textTransform: 'capitalize'}} key={i}>
                                <a href="#!" className={currentCategory === catg ? 'nav-link active' : 'nav-link'} onClick={() => {
                                    setCurrentCategory(catg)
                                    setPage(1)
                                }}>{catg}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}