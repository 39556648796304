import Row from "../common/Row";
import _ from 'lodash';
import Product from './Product';
import Pagination from '../common/Pagination';


export default function ProductContainer({products}){
    let chunked = _.chunk(products, 3)
    return(
        <div className="featured_wrapper">
            <div className="featured_slider">
                <div className="wrapper">
                    <div className="favorite-carousel">
                    {chunked.length ? chunked.map((row, i) => (
                        <Row key={i}>
                            {row.map((product, i) => (
                                <Product key={i} {...product}/>
                            ))}
                        </Row>
                    )): ''}
                    {chunked.length ?
                        <div className='col-12 d-flex justify-content-center'>
                            <Pagination/>
                        </div> : ''}
                    {!chunked.length ? <div className='d-flex justify-content-center p-5 h-100'>
                        No products found
                    </div>: ''}
                    </div>
                </div>
            </div>
        </div>
    )
}