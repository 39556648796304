import { useState } from 'react';

export default function Dropdown({name, children}) {
  const [isOpen, setOpen] = useState(false);
  const toggleDropdown = () => setOpen(!isOpen);
  const show = isOpen ? ' show' : ''
  return (
    <div className="dropdown" onMouseLeave={() => setOpen(false)}>
        <a className={'dropdown-toggle d-flex align-items-center hidden-arrow' + show} href="#!"
         id="navbarDropdownMenuAvatar" role="button" onClick={toggleDropdown}>
            {name}
        </a>
        <ul className={"dropdown-menu dropdown-menu-end" + show} aria-labelledby="navbarDropdownMenuAvatar">
            {children}
        </ul>
    </div>
  )
}