import './cart.css'
import Row from '../common/Row'
import CartBody from './CartBody'
import { useEffect, useState } from 'react';
import Loading from '../Home/Loading';
import { usePantry } from '../../Context'
import { SERVER } from '../../App';

export default function Cart(){
    const { cartProduct } = usePantry();
    let [currentProducts, setCurrentProducts] = useState([])
    useEffect(() => {
        const ids = cartProduct.map((pdct) => pdct._id)
        if(ids.length){
            fetch(`${SERVER}/cart/products?ids=${ids}`)
            .then(res=>res.json())
            .then(json=> {
                setCurrentProducts(json)
            })
        }
    }, [cartProduct, setCurrentProducts])
    const count = cartProduct.length
    if (count && !currentProducts.length) return (<Loading/>)
    const countText = count > 1 ? count + ' items' : count + ' item';
    return (
        <div className="card pantry-cart mt-5">
            <Row>
                <CartBody currentProducts={currentProducts} countText={countText} count={count}/>
            </Row>
            
        </div>
    )
}