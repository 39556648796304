import { useRef } from 'react'
import ErrorBox from '../common/ErrorBox'
import FormContainer from '../common/FormContainer'
import { InputRef } from '../common/Input'
import Submit from '../common/Submit'
import { useLogin } from '../hooks/useLogin'
import Loading from '../Home/Loading'
import './login.css'
import { FaDatabase } from 'react-icons/fa'

export default function Login(){
    const emailRef = useRef()
    const passwordRef = useRef()
    const {login, error, isLoading} = useLogin()
    const submitHandler = async (ev) => {
        ev.preventDefault();
        const password = passwordRef.current.value;
        const email = emailRef.current.value;
        await login(email, password);
    } 
    return (
        <FormContainer>
            <div className="heading">
                <h1 className="text text-large">Log In</h1>
            </div>
            <form name="signin" className="form oe_login_form" onSubmit={submitHandler}>
                <div class="form-group field-db">
                    <label for="db" class="col-form-label">Database</label>
                    <div class="input-group">
                        <input type="text" name="db" id="db" required="required" readonly="readonly" value="   cafe"
                               class="form-control o-control"/>
                        <span class="input-group-append">
                            <a role="button" href="/web/database/selector" class="btn btn-secondary">Select <FaDatabase/></a>
                        </span>
                    </div>
                </div>
                <InputRef name="email" id="email" type="name" label="Email Address" hidden={true} refName={emailRef}/>
                <InputRef name="password" id="password" type="password" label="Password" hidden={true} refName={passwordRef}/>
                {error && <ErrorBox error={error}/>}
                {isLoading && <Loading/>}
                <Submit label="Log In" disable={isLoading}/>
                <div class="justify-content-between mt-2 d-flex small">
                        <a href="/web/reset_password" class="reset-pwd">Reset Password</a>
                    </div>
            </form>
        </FormContainer>
    )
}