import { useState } from 'react'
import { SERVER } from '../../App'
import { useAuth } from '../../AuthContext'

export const useLogin = () => {
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  const { dispatch } = useAuth()

  const login = async (email, password) => {
    setIsLoading(true)
    setError(null)

    const response = await fetch(`${SERVER}/web/login`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ email, password })
    })
    const json = await response.json()

    if (!response.ok) {
      setIsLoading(false)
      setError(json.error)
    }
    if (response.ok) {
      await localStorage.setItem('user', JSON.stringify({_id: json._id, token: json.token, name: json.name}))
      await dispatch({type: 'LOGIN', payload: json})
      await setIsLoading(false)
      window.location.href = '/';
    }
  }

  return { login, isLoading, error }
}