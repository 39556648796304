import Navbar from './Navbar'
import Section from './Section'
import 'react-notifications/lib/notifications.css';

function App() {
  return (
    <>
        <Navbar/>
        <Section/>
    </>
  );
}

export default App;
