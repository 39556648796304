import { useState } from "react"

export default function FloatField({ name, id, label, hidden=false, refName=false, required=true, value=''}){
    const [newVal, setNewVal] = useState(value) 
    return (
        <div className="input-control">
            <label hmtlfor={name} className="input-label" hidden={hidden}>{label}</label>
            <input required={required} value={newVal} type="number" name={name} id={id} 
            className="input-field" placeholder={label} ref={refName}
            step={0.1} onChange={(ev, val) => setNewVal(val)}/>
        </div>
    )
}