import { useState } from "react"

export function InputRef({type, name, id, label, hidden=false, refName=false, change=()=>{}, required=true}){
    return (
        <div className="input-control">
            <label hmtlfor={name} className="input-label" hidden={hidden}>{label}</label>
            <input required={required} type={type} name={name} id={id} className="input-field" placeholder={label} onChange={change} ref={refName}/>
        </div>
    )
}

export function Input({type, name, id, label, hidden=false, change=()=>{}, required=true}){
    return (
        <div className="input-control">
            <label hmtlfor={name} className="input-label" hidden={hidden}>{label}</label>
            <input type={type} name={name} id={id} className="input-field" placeholder={label} onChange={change} required={required}/>
        </div>
    )
}

export function InputRefValue({type, name, id, label, hidden=false, refName=false, value=''}){
    const [newVal, setNewVal] = useState(value)
    const onChangeInput = (ev, newValue) => {
        setNewVal(newValue)
    }
    return (
        <div className="input-control">
            <label hmtlfor={name} className="input-label" hidden={hidden}>{label}</label>
            <input type={type} name={name} id={id} className="input-field" placeholder={label} onChange={onChangeInput} ref={refName} value={newVal}/>
        </div>
    )
}

