import { FaCartPlus } from 'react-icons/fa'
import { usePantry } from '../../Context'
import { NotificationManager } from 'react-notifications';

export default function AddCart({_id, total}){
    const { addToCart, cartProduct } = usePantry();
    const productToCart = () => {
        const newProduct = cartProduct.filter((crt, i) => crt._id === _id)
        if(newProduct.length){
            const quantity = newProduct[0].quantity + 1;
            total = total * quantity;
            addToCart({_id, quantity, total})
        } else {
            addToCart({_id, quantity: 1, total})
        }
        NotificationManager.success('Product added to cart', 'Added', 1000);
    }
    return (
        <div className="bg-primary rounded-circle d-flex align-items-center justify-content-center shadow-1-strong"
            onClick={() => productToCart()}>
            <p className="text-white mb-0 small"><FaCartPlus/></p>
        </div>
    )
}