import Back from "../common/Back"

export default function FormContainer({ children }){
    return (
        <main className="main login">
            <Back/>
            <div className="container">
                <section className="wrapper">
                    {children}
                </section>
            </div>
        </main>
    )
}