import logo from '../../images/logo2.png'
import backup from '../../images/floppy.svg'
import duplicate from '../../images/copy.svg'
import trash from '../../images/trash.svg'
import aradi from '../../images/aradi.gif'
import { useState } from 'react'

export default function DatabaseManager({selector=false}){
    const [troll, setTroll] = useState(false)
    const onAnyClick = () => {
        setTroll(true)
    }
    if(troll){
        return <div className="d-flex justiy-content-center" style={{height: '100vh'}}>
            <img src={aradi} className="d-flex m-auto" alt='Aradi.gif'/>
        </div>
    }
    return (
        <div className="container database-p">
    <div className="row">
        <div className="col-lg-6 offset-lg-3 o_database_list">
            <img src={logo} className="img-fluid d-block mx-auto" alt='logo2.png'/>
            <div className="list-group">
                <div className="list-group-item d-flex align-items-center">
                    <a className="d-block flex-grow-1" href="/web/login" style={{textDecoration: 'none'}}>
                        cafe
                    </a>
                    {!selector && <div className="btn-group btn-group-sm float-right">
                        <button  onClick={onAnyClick} type="button" className="btn-primary-real o_database_action btn btn-primary" data-db="pantry">
                            <img src={backup} style={{height: '13px'}} alt='Backup'/> Backup
                        </button>
                        <button  onClick={onAnyClick} type="button" className="o_database_action btn btn-secondary" data-db="pantry">
                            <img src={duplicate} style={{height: '13px'}} alt='Duplicate'/> Duplicate
                        </button>
                        <button  onClick={onAnyClick} type="button" className="o_database_action btn btn-danger" data-db="pantry">
                            <img src={trash} style={{height: '13px'}} alt='Trash'/> Delete
                        </button>
                    </div>}
                </div>
                <div className="list-group-item d-flex align-items-center">
                    <a className="d-block flex-grow-1" href="/"  style={{textDecoration: 'none'}}>
                        cafe_b
                    </a>
                    {!selector && <div className="btn-group btn-group-sm float-right">
                        <button  onClick={onAnyClick} type="button" className="btn-primary-real o_database_action btn btn-primary" data-db="cafe_b">
                            <img src={backup} style={{height: '13px'}} alt='Backup'/> Backup
                        </button>
                        <button  onClick={onAnyClick} type="button" className="o_database_action btn btn-secondary" data-db="cafe_b">
                            <img src={duplicate} style={{height: '13px'}} alt='Duplicate'/> Duplicate
                        </button>
                        <button  onClick={onAnyClick} type="button" className="o_database_action btn btn-danger" data-db="cafe_b">
                            <img src={trash} style={{height: '13px'}} alt='Trash'/> Delete
                        </button>
                    </div>}
                </div>
            </div>
            {selector && <div className="text-center mt-2">
                <a href="/web/database/manager" style={{textDecoration: 'none'}}>Manage databases</a>
            </div>}
            {!selector && <div className="d-flex mt-2">
                <button onClick={onAnyClick} type="button" className="btn-primary-real btn btn-primary flex-grow-1">Create Database</button>
                <button onClick={onAnyClick} type="button" className="btn-primary-real btn btn-primary flex-grow-1 ml-2">Restore Database</button>
                <button onClick={onAnyClick} type="button" className="btn-primary-real btn btn-primary flex-grow-1 ml-2">Set Master Password</button>
            </div>}
        </div>
    </div>
</div>
    )
}