import { usePantry } from "../../Context"
import AddCart from "./AddCart"
import { FaArchive, FaEdit, FaUndo } from 'react-icons/fa'
import { useAuth } from "../../AuthContext"
import { useSearchProduct } from "../hooks/useSearchProduct"

export default function Product({image, category, mrpPrice, cybroPrice, name, _id, active}){
    const {setCurrentCategory, setArchive, archivedProducts} = usePantry()
    const { user } = useAuth()
    const { deleteProduct } = useSearchProduct()
    const handleDelete = () => {
        if(active)deleteProduct(`product/archive/${_id}`, 'Archaved', true)
        else {
            deleteProduct(`product/unarchive/${_id}`, 'Unarchaved', false)
            setArchive(archivedProducts.filter((pd) => pd._id !== _id))
        }
    }
    const handleUpdate = async() => {
        window.location = `/product/update/${_id}`
    }
    return(
        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
            <div className="b_product">
                <div className="wrapper">
                    <div className="img_wrapper">
                        <img src={image} className="card-img-top" alt={name} />
                    </div>
                    <div className="product_info">
                        <div className="p_caregory" onClick={()=> setCurrentCategory(category)}>{category} </div>
                        <div className="p_name">{name}</div>
                        <p className="small text-danger"><s>₹{mrpPrice}</s></p>
                        <div className="p_price ">₹{cybroPrice}</div>
                    </div>
                    <div className="d-flex justify-content-between p-3 float-cart">
                         <AddCart _id={_id} total={cybroPrice}/>
                    </div>
                    {user && user.is_admin && (
                        <div className="d-flex justify-content-between p-3 float-edit-left">
                            <FaEdit onClick={handleUpdate}/>
                        </div>
                    )}
                    {user && user.is_admin && active && (
                        <div className="d-flex justify-content-between p-3 float-delete-left">
                            <FaArchive onClick={handleDelete}/>
                        </div>
                    )}
                    {user && user.is_admin && !active && (
                        <div className="d-flex justify-content-between p-3 float-delete-left">
                            <FaUndo onClick={handleDelete}/>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
