import FormContainer from "../common/FormContainer"
import Row from "../common/Row"
import { Input, InputRefValue } from "../common/Input"
import Submit from "../common/Submit"
import { useRef, useEffect, useState } from 'react'
import { useCreateProduct } from "../hooks/useCreateProduct"
import ErrorBox from '../common/ErrorBox'
import { useAuth } from "../../AuthContext"
import NotFound from "../common/NotFound"
import { usePantry } from "../../Context"
import { SERVER } from "../../App"
import Autocomplete from "../common/AutoComplete"
import { useParams } from "react-router-dom"
import FloatField from "../common/FloatField"

export default function UpdateForm(){
    const { user } = useAuth()
    const { category, setCategory} = usePantry();
    useEffect(() => {
        fetch(`${SERVER}/category`)
          .then(res=>res.json())
          .then(json=> setCategory(json))
      }, [setCategory])
    const { id } = useParams()
    const [product, setProduct] = useState({})
    useEffect(() => {
        (async() => {
            const res = await fetch(`${SERVER}/product/item/${id}`)
            const json = await res.json()
            setProduct(json)
        })()
    }, [id])
    const options = category.map((catg) => catg.name)
    let image = undefined;
    const name = useRef();
    const mrpPrice = useRef();
    const cybroPrice = useRef();
    const itemCode = useRef();
    const categoryRef = useRef();
    const barcodeRef = useRef();
    const { update, isLoading, error } = useCreateProduct()
    if(!user || !user.is_admin) return (<NotFound/>)
    const onChange = (e) => {
        let file = e.target.files[0];
        if(file){
            const reader = new FileReader();
            reader.onload = (ev) => {
                image = ev.target.result;
            }
            reader.readAsDataURL(file)
        }
    }
    const formSubmit = (ev) => {
        ev.preventDefault();
        const val = {
            name: name.current.value,
            mrpPrice: mrpPrice.current.value,
            cybroPrice: cybroPrice.current.value,
            itemCode: itemCode.current.value,
            category: categoryRef.current.value,
            barcode: barcodeRef.current.value,
            image: image
        }
         update(val, id)
    }
    return (
        <FormContainer>
            <div className="heading">
                <h1 className="text text-large">Update Product</h1>
            </div>
            <form className="form" encType="multipart/form-data" id="product-create-form" onSubmit={formSubmit}>
                <div className="section">
                    <InputRefValue type="text" name="name" label="Name" id="name" hidden={true} refName={name} value={product.name}/>
                    <FloatField required={false} type="number" name="mrpPrice" label="Mrp Price" id="mrpPrice" hidden={true} refName={mrpPrice} value={product.mrpPrice}/>
                    <FloatField required={false} type="number" name="cybroPrice" label="Cybro Price" id="cybroPrice" hidden={true} refName={cybroPrice} value={product.cybroPrice}/>
                    <InputRefValue type="text" name="itemCode" label="Code" id="itemCode" hidden={true} refName={itemCode} value={product.itemCode}/>
                    <Autocomplete
                        suggestions={options} type="text" name="category" id="category" label="Category" required={false} hidden={true} refName={categoryRef} value={product.category}/>
                    <InputRefValue type="text" name="barcode" label="Barcode" id="barcode" hidden={true} refName={barcodeRef} value={product.barcode}/>
                    <Input type="file" name="image" label="Image" id="image" hidden={true} change={onChange} required={false}/>
                </div>
                {error && <ErrorBox error={error}/>}
                <div className="section">
                    <Row>
                        <Submit label="Save" disable={isLoading}/>
                    </Row>
                </div>
            </form>
        </FormContainer>
    )
}