import { useEffect } from "react";
import { SERVER } from "../../App";
import { useAuth } from "../../AuthContext";
import { usePantry } from "../../Context";
import Back from "../common/Back";
import NotFound from "../common/NotFound";
import Row from "../common/Row";
import ProductContainer from "./ProductContainer";

export default function Archived(){
    const { archivedProducts, setArchive, page, setTotalPage } = usePantry()
    const { user } = useAuth()
    useEffect(() => {
        fetch(`${SERVER}/products/archive?page=${page}`, {
            headers: {
                "Authorization": `Bearer ${user ? user.token : ''}`
            },
        })
        .then(res => res.json())
        .then(json => {
          setArchive(json.products)
          setTotalPage(json.pages)
        })
    }, [setArchive, user, setTotalPage, page])
    if (!user || !user.is_admin) return <NotFound/>
    if (!archivedProducts || !archivedProducts.length){
        return (<div className='d-flex justify-content-center p-5 h-100'>
                <Back/>
                No products found
            </div>)
    }
    return (
        <section className="pantry_product">
          <Back/>
          <div className="f_wrapper">
            <div className="container-fluid">
              <Row>
                  <div className="col">
                    <ProductContainer products={archivedProducts}/>
                  </div>
              </Row>
            </div>
          </div>
        </section>
      );
}