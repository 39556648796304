import { usePantry } from '../../Context'
import Row from '../common/Row'
import { FaTrash } from 'react-icons/fa'

export default function CartItem({_id, image, cybroPrice, category, name}){
    const { cartProduct, removeFromCart, addToCart } = usePantry();
    const product = cartProduct.filter((pdct) => pdct._id  === _id)
    let quantity = product.length ? product[0].quantity : 1;
    if(!product.length) return
    const total = cybroPrice * quantity;
    return (
        <Row className="main align-items-center">
            <div className="col-2 d-none d-md-block">
                <img className="img-fluid" src={image} alt="img"/>
            </div>
            <div className="col">
                <Row className="text-muted d-none d-md-block">{category}</Row>
                <Row>{name}</Row>
            </div>
            <div className="col">
                <a href="#!" onClick={() => addToCart({_id, quantity: quantity > 1 ? quantity-1 : quantity, total: quantity > 1 ? Math.round((total - cybroPrice) * 100) / 100 : Math.round((total) * 100) / 100})}>-</a>
                <a href="#!">{quantity}</a>
                <a href="#!" onClick={() => addToCart({_id, quantity: quantity + 1, total: Math.round((total + cybroPrice) * 100) / 100})}>+</a>
            </div>
            <div className="col d-none d-md-block">
                ₹{cybroPrice} 
            </div>
            <div className="col">
                ₹{Math.round(total * 100) / 100} 
            </div>
            <div className='col'>
                <span className="close" onClick={() => removeFromCart(_id)}><FaTrash style={{cursor: 'pointer'}}/></span>
            </div>
        </Row>
    )
}