import { useRef } from 'react';
import { FaCartPlus, FaMoon, FaSearch, FaSun, FaUser } from 'react-icons/fa';
import { usePantry } from '../../Context'
import { useAuth } from '../../AuthContext'
import UserNav from './UserNav';
import { useSearchProduct } from '../hooks/useSearchProduct';


export default function Navbar(){
    const { cartProduct, setProducts, currentCategory, setPage, setCurrentCategory, page, darkMode, setDarkMode } = usePantry()
    const { user } = useAuth()
    const { search } = useSearchProduct()
    const  searchRef = useRef()
    let quantity = 0;
    cartProduct.forEach((pdct) => quantity += pdct.quantity)
    const searchProducts = (ev) => {
        setCurrentCategory(`/search/${searchRef.current.value}`)
        ev.preventDefault()
        setProducts([]);
        setPage(1);
        if(searchRef.current.value){
            search(`product/search/${searchRef.current.value}?page=${page}`, true)
        } else {
            search(`product/${currentCategory}?page=${1}`)
        }
    }
    return (
        <nav className="navbar sticky-top navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid justify-content-between">
                {!user && (<div className="d-none d-md-flex">
                    <a href="/web/login" className="btn btn-primary ms-2 me-1">Login</a>
                </div>)}
                {!user && (<div className="d-flex d-md-done d-lg-none">
                    <a href="/web/login"><FaUser/></a>
                </div>)}
                {user && <UserNav/>}
                {/* {darkMode == 'false' && <span className="d-flex d-md-none border-0 d-flex sm-w-25" onClick={() => {
                    setDarkMode('true')
                    localStorage.setItem('dark', true)
                }}>
                    <FaMoon/>
                </span>}
                {darkMode === 'true' && <span className="d-flex d-md-none border-0 d-flex sm-w-25" onClick={() => {
                    setDarkMode('false')
                    localStorage.setItem('dark', 'false')
                }}>
                    <FaSun/>
                </span>} */}
                <ul className="navbar-nav flex-row col-md-3 col-sm-6">
                    <form className="input-group w-100 my-auto d-sm-flex" onSubmit={searchProducts}>
                        <input
                            autoComplete="off"
                            type="search"
                            className="form-control rounded"
                            placeholder="Search"
                            ref={searchRef}/>
                        <span className="search-button input-group-text border-0 d-flex sm-w-25" onClick={searchProducts}>
                            <FaSearch/>
                        </span>
                    </form>
                </ul>
                <ul className="navbar-nav flex-row">
                    <li className="nav-item me-3 me-lg-1">
                    <a className="nav-link" href="/shop/cart">
                        <span className="badge badge-pill bg-primary">{quantity}</span>
                        <span><FaCartPlus/></span>
                    </a>
                    </li>
                </ul>
            </div>
        </nav>
    )
}