
import gpay from '../../images/gpay.svg'
import phonepe from '../../images/phonepe.svg'
import paytm from '../../images/paytm.svg'

export default function PayNow({total}){
    const NewUpi = `//pay?pa=CYBROSYS2008@icici&pn=CYBROSYS TECHNO SOLUTIONS PRIVATE LIMITED&tr=EZYS8129500777|msw&cu=INR&mc=7372&am=${total}`

    if(total){
        return (<div className='col-12' style={{textAlign: 'end'}}>
            <a className="btn btn-primary" style={{width: '20%'}} href={`upi:${NewUpi}`}>Pay Now</a>
        </div>)
    } else {
        return 
    }
}

export function PayNowIOS({total}){
    const NewUpi = `//upi/pay?pa=CYBROSYS2008@icici&pn=CYBROSYS TECHNO SOLUTIONS PRIVATE LIMITED&tr=EZYS8129500777|msw&cu=INR&mc=7372&am=${total}`
    if(total){
        return (<div className='col-12' style={{textAlign: 'center'}}>
            <a href={`gpay:${NewUpi}`}>
                <img src={gpay}/>
            </a>
            <a href={`phonepe:${NewUpi}`}>
                <img src={phonepe}/>
            </a>
            <a href={`paytm:${NewUpi}`}>
                <img src={paytm}/>
            </a>
        </div>)
    } else {
        return 
    }
}