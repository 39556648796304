import { SERVER } from "../../App"
import { usePantry } from "../../Context"
import { NotificationManager } from 'react-notifications';
import { useAuth } from "../../AuthContext";

export const useSearchProduct = () => {
    const {setProducts, setExist, setTotalPage, page, totalPage} = usePantry()
    const {user} = useAuth()
    const search = (domain, page=true) => {
        fetch(`${SERVER}/${domain}`)
            .then(res=>res.json())
            .then(json => {
                if(!json.length){
                    setExist(false)
                } else {
                    setExist(true)
                }
                if (page){
                    setProducts(json.products)
                    setTotalPage(json.pages)
                } else {
                    setProducts(json)
                }
            })
    }
    const deleteProduct = (domain, message="Deleted", refresh=true) => {
        fetch(`${SERVER}/${domain}`, {
            method: "PATCH",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`
            }
        })
            .then(res=>res.json())
            .then(json => {
                if(json.message){
                    NotificationManager.success(json.message, message, 1000);
                    if(refresh) search(`product/All?page=${page}`)
                } else {
                    NotificationManager.error(json.error, 'Failed', 1000);
                }
            })
    }
    return {
        search,
        deleteProduct
    }
}