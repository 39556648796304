import { createContext, useContext, useState } from "react";

const PantryContext = createContext();

export default function ContextProvider({ children }){
    const [categoryClass, setCategoryClass] = useState('container col-lg-2 py-4top d-none d-md-block');
    const [products, setProducts] = useState([])
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [productExist, setExist] = useState(true)
    const [currentCategory, setCurrentCategory] = useState('All');
    const [archivedProducts, setArchive] = useState([])
    const [cartProduct, setCartProduct] = useState(JSON.parse(localStorage.getItem('products')) || [])
    const [category, setCategory] = useState([])
    const [darkMode, setDarkMode] = useState(localStorage.getItem('drak') || 'false')
    const toggleCategoryClass = () => {
        if(categoryClass === 'container col-lg-2 py-4top d-none d-md-block'){
            setCategoryClass('container col-lg-2 py-4top')
        } else {
            setCategoryClass('container col-lg-2 py-4top d-none d-md-block')
        }
    }
    const addToCart = (product) => {
        let newProduct = cartProduct ? [...cartProduct] : []
        if(cartProduct){
            newProduct = newProduct.filter((crt, i) => crt._id !== product._id)
        }
        newProduct.push(product)
        setCartProduct(newProduct);
        localStorage.setItem('products', JSON.stringify(newProduct));
    }
    const removeFromCart = (_id) => {
        const cart = cartProduct.filter((crt, i) => crt._id !== _id)
        setCartProduct(cart);
        localStorage.setItem('products', JSON.stringify(cart))
    }
    if(Boolean(darkMode === 'true')){
        document.body.classList.add('dark-mode');
    } else {
        document.body.classList.remove('dark-mode')
    }
    return (
        <PantryContext.Provider value={{categoryClass, toggleCategoryClass, products, setProducts,
         currentCategory, setCurrentCategory, cartProduct, addToCart, removeFromCart, category, setCategory,
         productExist, setExist, setCartProduct, page, setPage, totalPage, setTotalPage, darkMode, setDarkMode,
         archivedProducts, setArchive}}>
            {children}
        </PantryContext.Provider>
    );
}

export const usePantry = () => useContext(PantryContext);